import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import {Container, Grid, SubTitle} from '../styles/global';
import styled from "styled-components"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


const Thumbs = styled(Img) `
  margin: 20px 0;
`

const ReserverButton = styled.a `
  border: 1px solid #000;
  color: #000;
  padding: .8em 1.8em;
  display:inline-block;
  text-decoration:none;
  font-weight: bold;
  margin-top: 0.5em;
  transition: color 0.6s, background-color .6s;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`
const Carte = styled.div `
padding-top: 15px;
    background-color: #000;
    text-align:center;
    a {
      color: #fff;
      text-decoration:none;
    }
`

const LinkInvisibleB = styled.a `
    color: #000;
    text-decoration:none;
`

// markup
const NousPage = () => {


   const data = useStaticQuery(graphql`
  query {
    image1: file(relativePath: { eq: "contact_1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "contact_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }   
    }
    carte: file(relativePath: { eq: "carte.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }   
    }
  }
`)
/*
  const data = useStaticQuery(graphql`
     {
        file(relativePath: { eq: "salon_1.jpg" }) {
        childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        
      }
    }
  `)
*/
  return (
    <Layout>
    <main style={pageStyles}>
      <Container>
        <title>Contact – Tête à Clak</title>
        <Grid className={'inverse'}>
            <div className={'grid-12 col gauche'}>
              <Thumbs fluid={data.image1.childImageSharp.fluid} alt="Détail de la vitrine" />
              <Thumbs fluid={data.image2.childImageSharp.fluid} alt="Détail de la vitrine" />
              <p><strong>DESIGN GRAPHIQUE / Direction artistique, textes et photographies</strong><br/>
                Envie d’ailes_ crea@enviedailes.com<br/>
                021 921 37 18_<LinkInvisibleB href="https://www.enviedailes.com/" target="_blank">www.enviedailes.com</LinkInvisibleB></p>
                <p><strong>SITE INTERNET / Développement et maintenance</strong><br/>
                La Boîte à Malice Sàrl_ Montreux<br/>
                021 519 20 21_<LinkInvisibleB href="https://www.laboiteamalice.ch" target="_blank">www.laboiteamalice.ch</LinkInvisibleB></p>

            </div>
            <div className={'grid-12 col droite'}>
            <SubTitle>Tête à Clak / Créateur capillaire</SubTitle>
 
            <p>Rue du Centre 8_<strong>1800 Vevey</strong><br/>
            (021) 921 17 79<br/>
            <ReserverButton href="https://salonkee.ch/salon/tete-a-clak" target="_blank" rel="noopener noreferrer">Réserver à Vevey</ReserverButton></p>
              
            <p>Prise de rendez-vous via notre site ou par téléphone<br/>
            Lundi_ fermé<br/>
            Mardi au vendredi_08h30_18h30<br/>
            Samedi_08h30_13h30</p>
              
            <SubTitle>La barbe à Fernand / Barbier-coiffeur homme</SubTitle>
              
            <p>Rue du Centre 8_<strong>1800 Vevey</strong><br/>
            (021) 921 17 79</p>

            <p>Prise de rendez-vous via notre site ou par téléphone<br/>
            Lundi_ fermé<br/>
            Mardi au vendredi_08h30_18h30<br/>
            Samedi_08h30_13h30</p>

            <Carte><a href="https://goo.gl/maps/7Zz6tdUyEnFxxXJN7">Cliquer pour agrandir le plan<Thumbs fluid={data.carte.childImageSharp.fluid} alt="" /></a></Carte>

            </div>
        </Grid>
        </Container>
    </main>
    </Layout>
  )
}

export default NousPage
